.card-container {
    display: flex;
    justify-content: center;
    max-width: 1600px;
    flex-direction: column;
}

.card-wrapper {
    display: flex;
}

.wine-header{
    font-size: large;
    margin-top: 12px;
}