@import url('assets/colors.css');


.App {
  text-align: left;
  background-color: var(--darkest);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-content {
  background-color: var(--darkest);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--lightest);
}

.App-header {
  display: flex;
  width: 100%;
  background-color: var(--medium);
  color: var(--lightest);
  justify-content: left;
  flex-direction: column;
  text-align: center;
  margin-top: 70px;
}

.App-header p {
  font-size: calc(5px + 2vmin);
  font-weight: normal;
}

.App-link {
  color: #61dafb;
}

.card-container {
  overflow: auto;
}

.card {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 10px;
}

.comment {
  margin-top: 30px;
  font-size: 28px;
  margin-bottom:  -30px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  /* apply the following styles only if the screen width is less than or equal to 600px */
  .comment {
    font-size: 16px;
  }
  .App-header {
    margin-top: 30px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
