@import url('../../assets/colors.css');

.navbar {
  background-color: var(--medium);
  display: flex;
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: calc(5px + 2vmin);
  z-index: 2;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  color: var(--lightest);
  text-decoration: none;
  padding: 5px;
}

.nav-link:hover {
  border-bottom: 2px solid var(--lightest);
}

@media screen and (max-width: 600px) {
  /* apply the following styles only if the screen width is greater than or equal to 768px */
  .navbar {
    font-size: calc(max(16px, 5px + 2vmin));
    padding: 10px;
  }
  .nav-item {
    margin-right: 5px;
  }
}