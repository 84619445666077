@import url('../../assets/colors.css');

.timeline {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: var(--medium);
  }
  
  .timeline-event {
    position: relative;
    margin: 50px 0;
  }
  
  .timeline-event::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--lightest);
    z-index: 1;
  }
  
  .timeline-event-date {
    position: absolute;
    top: -20px;
    left: 25%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    color: var(--lightest);
  }
  
  .timeline-event-content {
    padding: 10px;
    align-items: flex-end;
    background-color: var(--lightest);
    color: var(--darkest);
    border-radius: 5px;
  }

  @media (min-width: 600px) {
    .timeline-event-content {
      margin-left: 300px;
    }
  }

  .timeline-event-content p {
    font-size: 16px;
    word-wrap: break-word; /* allows long words to break to the next line */
    max-width: 200px; /* restricts the width of the h3 element to 100px */
    align-self: auto;
  }
  
  .timeline-event-content h3 {
    font-size: 24px;
  }
  