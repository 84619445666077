@import url('./../../assets/colors.css');

.card {
    width: 300px;
    border: 1px solid var(--lightest);
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
  }
  
  .card img {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 15px;
  }
  
  .card-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .card-content p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .card-content a {
    display: inline-block;
    background-color: var(--darkest);
    color: var(--lightest);
    padding: 0px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }

  
  
  .card-content a:hover {
    background-color: #555;
  }

  .card-links a {
    display: inline-block;
    background-color: var(--medium);
    color: var(--lightest);
    padding: 0px 15px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .card-links p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
    justify-content: flex-end;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
  }

  .paragraph-style {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
  }