@import url('../../assets/colors.css');

.comment-text {
    background-color: transparent;
    color: var(--medium);
    transition: background-color 0.5s ease;
  }
  
  .comment-text:hover {
    background-color: var(--lightest);
  }
  
  .comment span {
    display: inline-block;
    animation: spin 1s linear infinite;
  }
  