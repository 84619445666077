.tech-style {
    width: 70px;
    height: 70px;
    margin: 5px;
    margin-bottom: 50px;
  }
  
  @media (max-width: 600px) {
    .tech-style {
      width: 60px;
      height: 60px;
    }
  }

.work-tech-style {
    width: 50px;
    height: 50px;
    margin: 5px;
    margin-bottom: 50px;
  }
  
  @media (max-width: 600px) {
    .tech-style {
      width: 45px;
      height: 45px;
    }
  }
